import React, { useState, useEffect } from 'react';
import images from '../assets/images';
import './styles/Mask.scss';

const Mask = () => {
    const [currentScene, setCurrentScene] = useState('none'); // Start with no scene selected
    const [animate, setAnimate] = useState(false); // Control animation state
    const [isHovered, setIsHovered] = useState(false);

    // Ensure unique keys for both content and after content based on currentScene and animate state
    const contentKey = `content-${currentScene}-${animate}`;
    const afterContentKey = `after-content-${currentScene}-${animate}`;

    const changeScene = (scene) => {
        if (scene !== currentScene) {
            setCurrentScene(scene);
            // Reset animation
            setAnimate(false);
            // Delay slightly before restarting animation to ensure it's noticed by the browser
            setTimeout(() => setAnimate(true), 10);
        }
    };

    const containerStyle = {
        transform: isHovered ? 'scale(1.05)' : 'scale(1.0)',
        transition: 'transform 0.3s ease',
    };

    return (
        <>  
            <div className="wrap">
                <div className="vision-pro-container" style={containerStyle}
                     onMouseEnter={() => setIsHovered(true)}
                     onMouseLeave={() => setIsHovered(false)}>
                    {/* Key ensures element is re-rendered when scene or animate state changes */}
                    <div key={contentKey} className={`masked-content look-around-${currentScene}`}></div>
                    <div key={afterContentKey} className={`masked-content-after look-around-${currentScene} ${animate ? 'animate' : ''}`}></div>
                    <img src={images.visionProTransparent} alt="Vision Pro Frame" className="vision-pro-frame" style={{ width: '100%', height: 'auto' }}/>
                </div>
                
            </div>
            <div className = 'spacer-flex-two'></div>
            <h1 className = 'select'>Select Blueprint</h1>
            <div className="demo-controls">
                {/* Thumbnails and Buttons for changing scenes */}
                <div className="thumbnail-container">
                    <img src={images.bluePrintGreen} alt="blueprint" onClick={() => changeScene('kitchen')}/>
                    <img src={images.bluePrintBlue} alt="blueprint2" onClick={() => changeScene('living')}/>
                    <img src={images.bluePrintBlack} alt="blueprint3" onClick={() => changeScene('coffee')}/>
                </div>

                <div className="button-container">
                    <button onClick={() => changeScene('kitchen')}><p>Kitchen</p><p className="sub-title-by">By Blueprint</p></button>
                    <button onClick={() => changeScene('living')}><p>Living Room</p><p className="sub-title-by">By Blueprint</p></button>
                    <button onClick={() => changeScene('coffee')}><p>Coffee</p><p className="sub-title-by">By Blueprint</p></button>
                </div>
            </div>
        </>
    );
};

export default Mask;

import React, {useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Mask from '../components/Mask'
import images from '../assets/images'
import './styles/About.scss'; 
//import images from '../assets/images'


const About = () => {
  const [textOne, setTextOne] = useState('Default text one');
  const [textTwo, setTextTwo] = useState('Default text two');

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setTextOne('VR');
        setTextTwo('for the Vision Pro');
      } else {
        setTextOne('virtual reality');
        setTextTwo('now on the Apple Vision Pro');
      }
    }

    window.addEventListener('resize', handleResize);
    // Initialize text based on current size
    handleResize();

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []); 

  return(
    <>
            
      <Navbar/>
    <div className = 'panel-one-container'>
      <div className = 'spacer-nav'></div>
      <div className = 'spacer-flex-one'></div>
        <div className = 'intro-wrapper'>
          <div className = 'intro-container'>
            <h1>Blueprint, the all-in-one app for {textOne} spaces</h1>
            <h2>customize, save, and share your picture perfect worlds</h2>
            <h1></h1>
            <h2 > 
              <span><a className = 'intro-links' href = '#scroll-demo'>Demo</a><a className = 'arrow'>&#8681;</a></span>  
              <span><a className = 'intro-links' href = '#scroll-feature'>Features</a><a className = 'arrow'>&#8681;</a></span>  
              <span><a className = 'intro-links' href = "#community-scroll">Community</a><a className = 'arrow'>&#8681;</a></span>  
            </h2>
            <div className = 'spacer-six'></div>
            <h1>Download Blueprint {textTwo}</h1>
          </div>
          <div className = 'intro-buttons'>
            <div className =  'app-logo'><a href = "https://apps.apple.com/us/app/blueprint-save-your-space/id6451072908"><img src = {images.bluePrintAppLogo} alt = 'logo'/></a></div>
            <div className = 'app-download' href><a href = "https://apps.apple.com/us/app/blueprint-save-your-space/id6451072908"><img  src = {images.downloadApp} alt = 'download'/></a></div>
          </div>
          </div>
          <div className = 'spacer-flex-one'></div>
          </div>
      <div className = 'mask-wrapper'>
      <div className = 'spacer-flex-one'></div>

        <div id = 'scroll-demo'></div>  
      <Mask/>
      
      <div className = 'spacer-flex-one'></div>
      </div>
      <div className = 'feature-section'>
      <div className = 'spacer-flex-one'></div>
      <div id = 'scroll-feature' className="feature-wrapper">
            {/* First Row: Image on the left, text on the right */}
            <div className="feature-row">
                <div className="feature-object-one">
                    <img src={images.portalRotate} alt="First Object" />
                </div>
                <div  className="feature-text-one">
                  <div className = 'feature-description'>
                  <h1> Objects </h1>
                    <h4>Lifelike volumetric models
                    </h4>
                    </div>
                    <div className ='feature-bottom'>
                    <p>Browse our library
                      or upload your own files.
                    
                   Resize and customize objects at your will.</p>
                    </div>
                </div>
            </div>
            
            {/* Second Row: Text on the left, image on the right */}
            <div className="feature-row feature-row-reverse">
            <div className="feature-object-two">
                    <img src={images.objectPlace} alt="Second Object" />
                </div>
                <div className="feature-text-two" >
                  <div className = 'feature-description'>
                  <h1> Portals</h1>
                    <h4> Windows into your dreamscape</h4>
                    </div>
                    <div className ='feature-bottom'>

                    <p> Feel like you're right there with spatial audio. 
                     Portals are motion landscapes with perspective adjustment. </p>
                    </div>
                </div>

            </div>
        </div>
      <div className = 'photos-wrapper'>
        <div className="photos-container">
      <div className="photos-column">
        <img src= {images.imageLogo} alt="Description1" />
        <h3>Images</h3>
        <p> Use any combination of pictures from your gallery to personalize Blueprints</p>
      </div>
      <div className="photos-column">
        <img src={images.noteLogo} alt="Description2" />
        <h3>Notes</h3>
        <p> Never forget your to-do list again with notes that automatically save in place</p>
      </div>
    </div>
    </div>
    <div className = 'flex-why-wrapper'>
    <div className="flex-why">
      <div className="column-why">
        <h2> Customize </h2>
        <p> Use 4+ media types to shape and design your space</p>
      </div>
      <div className="column-why">
      <h2> Save </h2>
        <p> Blueprints are automatically saved in real time</p>
      </div>
      <div className="column-why">
      <h2> Share </h2>
        <p> Access and upload your Blueprints at any point </p>
      </div>
    </div>
    </div>
    <div className = 'spacer-flex-one'></div>
    </div>
    <div className = 'panel-four-wrapper'>
    <div className = 'spacer-flex-one'></div>
    <div className = 'spacer-flex-two'></div>
    <div className = 'community-head'>Join the Blueprint Community
    <p classname='community-sub'> We value our user interaction and feedback</p></div>

    <div className = 'spacer-flex-two'></div>

    <div className = 'community-wrapper'>
      
    <div className = 'community-row'>
    <div className = 'community-download'><a href = "https://apps.apple.com/us/app/blueprint-save-your-space/id6451072908"><img  src = {images.downloadApp} alt = 'download'/></a></div>
    <div className = 'community-feedback'><button> <img src = {images.mailLogo} alt = 'feedback'/> <p> Feedback </p> </button></div>
    </div>


    </div>
    <div className = 'spacer-flex-one'></div>
    <div id = 'community-scroll' className = 'spacer-flex-two'></div>
    </div> 
    <div className = 'footer'>
    <div className = 'copy-right'>
      <p> © Blueprint Labs Inc., 2024</p>
    </div>
    <div className = 'footer-links'>
    <p><a href = "https://apps.apple.com/us/app/blueprint-save-your-space/id6451072908">App Store </a></p>
      <p> <a href = "https://apps.apple.com/us/app/blueprint-save-your-space/id6451072908">Twitter</a> </p>
      <p> <a href = "https://www.reddit.com/user/getblueprint/">Reddit</a> </p>
      <p> <a href= "mailto:blueprintar.contact@gmail.com">Contact </a></p>
    </div>
    </div>
    </>
  );
};

export default About;

// Blueprint, the all-in-one app for virtual workplaces
// customize, save, and share your picture perfect work space
 
import React from 'react';
import './styles/Navbar.scss'; // Ensure this path matches your project structure
import images from '../assets/images'
import {TwitterLogo, RedditLogo, AppStoreLogo} from '../components/Logos'


const NavBar = () => {
  return (
    <div className ='nav-wrapper'>
    <nav className='nav-container'>
      <img src = {images.bluePrintLogo} alt = 'logo' img/>
      <div className = 'logos'>
      <TwitterLogo />
      <a href = "https://www.reddit.com/user/getblueprint/"> <RedditLogo /> </a>
      <a href = "https://apps.apple.com/us/app/blueprint-save-your-space/id6451072908"><AppStoreLogo /></a>
      </div>
    </nav>
    </div>
  );
};

export default NavBar;

//import { NavLink } from 'react-router-dom';
//import images from '../assets/images'
import visionProTransparent from './vision_pro_transparent.png'
import insideFrame from './inside_content.png'
import bluePrintLogo from './blueprintlogo.webp'
import bluePrintAppLogo from './bp_logo.webp'
import downloadApp from './download_app.webp'
import bluePrintBlack from './blueprintblack.jpg'
import bluePrintBlue from './blueprintblue.jpg'
import bluePrintGreen from './blueprintgreen.jpg'
import portalRotate from './spaceShip.PNG'
import objectPlace from './portalimage.PNG'
import kitchenBefore from './kitchenbefore.webp'
import kitchenAfter from './kitchenafter.webp'
import coffeeBefore from './coffeebefore.webp'
import coffeeAfter from './coffeeafter.webp'
import livingBefore from './livingbefore.webp'
import livingAfter from './livingafter.webp'
import imageLogo from './images_logo.png'
import noteLogo from './notes_logo.png'
import twitterLogo from './twitterlogo.webp'
import redditLogo from './reddit logo.webp'
import mailLogo from './mail_logo.svg'

const images = {
    visionProTransparent,
    insideFrame,
    bluePrintLogo,
    bluePrintAppLogo,
    downloadApp,
    bluePrintBlack,
    bluePrintBlue,
    bluePrintGreen,
    portalRotate,
    objectPlace,
    kitchenBefore,
    kitchenAfter,
    coffeeBefore,
    coffeeAfter,
    livingBefore,
    livingAfter,
    imageLogo,
    noteLogo,
    twitterLogo,
    redditLogo,
    mailLogo
}

export default images

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './views/About';
import './App.scss';

const AppWrapper = () => {
  return (
    <div className="App">

      <Routes>
        <Route path="/" element={<About />} />
      </Routes>
    </div>

  );
};

function App() {
  return (
    <Router>
      <AppWrapper />
    </Router>
  );
}

export default App;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <head>
    <link rel="preload" href="/assets/kitchenafter.webp" as="image"/>
    <link rel="preload" href="/assets/kitchenbefore.webp" as="image"/>
    <link rel="preload" href="/assets/coffeeafter.webp" as="image"/>
    <link rel="preload" href="/assets/coffeebefore.webp" as="image"/>
    <link rel="preload" href="/assets/livingafter.webp" as="image"/>
    <link rel="preload" href="/assets/kitchenbefore.webp" as="image">
    </link>
    </head>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
